import React from 'react';
import { Button } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styled from '@emotion/styled';

import { getTheme } from '../utility/theme';
import Layout from '../components/Layout';
import { COLOR } from '../constants';

const muiTheme = (userTheme: string) =>
  createMuiTheme({
    palette: {
      type: userTheme === 'dark' ? 'dark' : 'light',
      primary: {
        main: COLOR.PRIMARY,
      },
      text: {
        primary: userTheme === 'dark' ? '#ffffff' : '#333333',
      },
    },
  });

const Row = styled.div`
  margin-bottom: 1rem;
`;

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export default function AddJournal() {
  const [state, setState] = React.useState({});

  const userTheme = getTheme();
  const theme = muiTheme(userTheme);

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log('state:', state);
    const body = encode({
      'form-name': 'add-journal',
      ...state,
    });
    console.log('body:', body);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body,
    })
      .then(a => {
        console.log('a:', a);
        alert('Journal Added');
      })
      .catch(error => alert(error));
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <h1>Add Journal</h1>
        <form
          name="add-journal"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="add-journal" />
          <input type="hidden" name="bot-field" />
          <Row>
            <TextField label="PMID" name="pmid" type="text" onChange={handleChange} fullWidth required></TextField>
          </Row>
          <Row>
            <TextField
              label="Notes"
              type="text"
              onChange={handleChange}
              name="notes"
              multiline
              fullWidth
              rows="6"
            ></TextField>
          </Row>
          <p>
            <Button type="submit" color="primary" variant="contained">
              Submit
            </Button>
          </p>
        </form>

        <br />
        <br />
        <br />
        <p>
          <a
            href="https://docs.google.com/spreadsheets/d/1X-QkjHU9nUr-_kdJo_gCKk2A_mWDQAuc03Tp6QVE_Co/edit#gid=1593546933"
            target="_blank"
            rel="noopener noreferrer"
          >
            Check list of journals
          </a>
        </p>
      </Layout>
    </ThemeProvider>
  );
}
